<template>
  <div class="animated fadeIn">
    <b-tabs v-if="isUserManager" lazy>
      <b-tab :active="activeTabIndex == 0" @click="tabChanged" class="px-md-3 px-0">
        <template slot="title">
          <p>Modelos</p>
        </template>
        <models ref="models"></models>
      </b-tab>
      <b-tab :active="activeTabIndex == 1" @click="tabChanged" class="px-md-3 px-0">
        <template slot="title">
          <p>Arquivos</p>
        </template>
        <assets ref="assets"></assets>
      </b-tab>
    </b-tabs>

    <p v-else>Apenas usuários gerentes possuem acesso à essa função</p>
  </div>
</template>

<script>
import models from './models';
import assets from './assets';
import shared from '@/shared/shared';

export default {
  name: 'etiquetas',
  components: { models, assets },

  data() {
    return {
      activeTabIndex: 0,
      isUserManager: false,
      authUser: null
    }
  },

  mounted() {
    this.$store.dispatch('app/changePage', { title:'Etiquetas', size: 4 });
    this.setIsUserManager();
    this.tabChanged();
  },

  methods: {
    onFiltering() {
      if (this.$refs.models) {
        this.$refs.models.findAll();
      }

      if (this.$refs.assets) {
        this.$refs.assets.findAll();
      }
    },

    tabChanged() {
      this.$nextTick(() => {
        this.onFiltering();
      });
    },

    setIsUserManager() {
      this.authUser = shared.getLocalStorageObj('auth/user');

      if (this.authUser && this.authUser.profile && this.authUser.profile.modules) {
        let profileModule = this.authUser.profile.modules.find(pm => {
          return pm.name && pm.name.toString().toUpperCase() == "ITEMS"
        });

        this.isUserManager = profileModule && profileModule.canExecute;
      }
    }
  }
}
</script>
