<template>
  <div class="animated fadeIn">
    <b-row>
      <div class="col-12">
        <b-card>
          <b-row class="mx-md-n3 mx-1">
            <div class="col-xl col-12 mb-xl-0 mb-3">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  @keyup.enter="searchButtonPressed"
                  placeholder="Procurar por..."
                />
                <b-input-group-append>
                  <b-button size="md" text="Button" variant="outline-info" @click.passive="searchButtonPressed">
                    <i class="cui-magnifying-glass"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-xl-2 col-sm col-12 mb-sm-0 mb-3">
              <model-list-select
                :list="types"
                option-value="value"
                option-text="label"
                v-model="selectedType"
                placeholder="Selecione o Tipo"
              />
            </div>
            <div class="col-sm-auto col-12 px-sm-0 px-3 mb-sm-0 mb-3">
              <b-dropdown id="download-example-dropdown" variant="secondary" class="mobile-btn-100">
                <template #button-content>
                  <i class="fa fa-download"></i> Baixar exemplo
                </template>
                <b-dropdown-item-button @click="downloadExample('itmlot')" :disabled="saving">Lote</b-dropdown-item-button>
                <b-dropdown-item-button @click="downloadExample('locati')" :disabled="saving">Endereço</b-dropdown-item-button>
              </b-dropdown>
            </div>
            <div class="col-sm-auto col-12">
              <button type="button" class="btn btn-success mobile-btn-100" @click="openNewFormModal" :disabled="saving">
                <i class="fa fa-plus"></i> Novo Modelo
              </button>
            </div>
          </b-row>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div v-if="loadingRows" class="mb-5 pb-5">
              <loading-animation />
            </div>

            <div v-if="!loadingRows && !templates.length" class="mb-3">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Nenhum Modelo encontrado
                </div>
              </div>
            </div>

            <div v-if="!loadingRows && templates.length" class="mb-3">
              <div v-for="(template, index) in templates" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">

                <div class="col">
                  <div class="row">
                    <div class="col-xl col-12">
                      <span class="font-weight-bold">Nome: </span>{{template.name}}
                    </div>
                    <div class="col-xl-2 col-6">
                      <span class="font-weight-bold">Tipo: </span>
                      <span v-if="template.type === 'locati'" class="badge badge-success">Endereço</span>
                      <span v-else class="badge badge-primary">Lote</span>
                    </div>
                    <div class="col-xl-2 col-6">
                      <span class="font-weight-bold">Ativo: </span>
                      <span v-if="template.active" class="badge badge-secondary">Sim</span>
                      <span v-else class="badge badge-danger">Não</span>
                    </div>
                    <div class="col-xl-2 col-sm-6 col-12">
                      <span class="font-weight-bold">Criado em: </span>{{template.formattedCreatedAt}}
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                      <span class="font-weight-bold">Modificado em: </span>{{template.formattedUpdatedAt}}
                    </div>
                  </div>
                </div>

                <div class="col-auto">
                  <b-button size="sm" @click.prevent="editItem(index)" :disabled="saving || editIndex > -1">
                    <i class="fa fa-edit"></i> Editar
                  </b-button>
                </div>

              </div>
            </div>

            <nav>
              <b-pagination
                v-if="totalRows"
                align="center"
                :limit="10"
                :total-rows="totalRows"
                :per-page="perPage"
                prev-text="Anterior"
                next-text="Próximo"
                v-model="currentPage"
                @change="pageChanged"
                :disabled="loadingRows"/>
            </nav>
          </div>
        </div>
      </div>
    </b-row>

    <b-modal
      centered
      size="lg"
      lazy
      :title="currentTemplate && currentTemplate.id ? 'Editar Template' : 'Novo Template'"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showFormModal"
      @hidden="formModalClosed">
      <b-container v-if="currentTemplate">
        <div v-if="loadingCurrentTemplate || saving">
          <loading-animation />
        </div>

        <div v-else>
          <div class="row">

            <div class="col-lg-4 col-12">
              <b-form-group
                id="name"
                label="Nome"
                label-for="name"
                class="mb-0"
              >
                <b-form-input
                  required
                  id="name"
                  type="text"
                  v-model="currentTemplate.name"/>
              </b-form-group>

              <div class="my-2">
                <span>Tipo</span>

                <b-form-radio-group
                  v-model="currentTemplate.type"
                >
                  <b-form-radio name="types" value="itmlot">
                    Lote
                  </b-form-radio>
                  <b-form-radio name="types" value="locati">
                    Endereço
                  </b-form-radio>
                </b-form-radio-group>
              </div>

              <b-form-checkbox v-model="currentTemplate.active" switch>
                Ativo
              </b-form-checkbox>

              <b-form-checkbox v-model="currentTemplate.includeItemBarcode" switch>
                Incluir código de barras do item
              </b-form-checkbox>

              <div class="card mt-2">
                <div class="card-header">Template</div>
                <div class="card-body mb-0 p-2">
                  <h1 v-if="currentTemplate.content && currentTemplate.content.length" class="text-success text-center">
                    <i class="fa fa-check fa-2x"></i>
                  </h1>
                  <input type="file" ref="uploadFileInput" style="display: none" @change="uploadFile">

                  <div class="row mx-0">
                    <button class="col btn btn-primary mx-1" @click="$refs.uploadFileInput.click()">
                      <i class="fa fa-upload"></i> Upload
                    </button>
                    <button v-if="currentTemplate.id && currentTemplate.content && currentTemplate.content.length"
                      class="col btn btn-secondary mx-1" @click="downloadContent">
                      <i class="fa fa-download"></i> Baixar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-12">
               <div class="card">
                <div class="card-header">Imagens</div>
                <div class="card-body mb-0">
                  <div v-for="(image, index) in currentTemplate.images" class="row mx-0 border align-items-center py-2" :key="index">

                    <div v-if="image.asset && image.asset.url && image.asset.url.length" class="col-sm-4 col-12 p-1">
                      <img :src="image.asset.url" width="100">
                    </div>

                    <div class="col-sm-8 col-12">
                      <b-form-group
                        label="Nome"
                        :label-for="'imageName-' + index"
                      >
                        <b-form-input
                          required
                          :id="'imageName-' + index"
                          type="text"
                          v-model="image.name"/>
                      </b-form-group>

                      <div class="row mx-0">

                        <div class="col-12 mb-2 px-0">
                          <b-button :disabled="loadingCurrentTemplate || saving"
                            @click="copyImageCode(index)" variant="primary">
                            <i class="fa fa-code"></i> Copiar código
                          </b-button>
                        </div>
                        <div class="col-auto pl-0 pr-1">
                          <b-button size="sm" :disabled="loadingCurrentTemplate || saving"
                            @click="openImageSelectModal(index)">
                            <i class="fa fa-exchange"></i> Alterar
                          </b-button>
                        </div>
                        <div class="col-auto pr-0 pl-1">
                          <b-button size="sm" :disabled="loadingCurrentTemplate || saving"
                            variant="danger" @click="openImageDeleteModal(index)">
                            <i class="fa fa-trash"></i> Remover
                          </b-button>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div class="row justify-content-center mt-3">
                    <button class="btn btn-success col-auto" @click="openImageSelectModal(-1)">
                      <i class="fa fa-plus"></i> Adicionar Imagem
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-container>

      <template v-if="currentTemplate" slot="modal-footer">
        <div class="row justify-content-end w-100 mx-0">
          <div class="col-auto">
            <b-button variant="primary" @click="save"
              :disabled="loadingCurrentTemplate || saving">
              Salvar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      centered
      size="xl"
      lazy
      title="Escolher imagem"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showImageSelectModal">
      <b-container>
        <assets
          ref="assetList"
          :additionalConditions="[{ field: 'type', value: 'I' }]"
          :onClick="imageSelected"
        >
        </assets>
      </b-container>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="showImageSelectModal = false"
        >
          <i class="fa fa-times"></i> Cancelar
        </button>
      </template>
    </b-modal>

    <confirmation-modal
      id="image-delete-confirmation-modal"
      msg="Tem certeza de que deseja remover essa imagem?"
      :ok="deleteImage"
    ></confirmation-modal>
  </div>
</template>

<script>
import { httpClient } from '@/service'
import Animation from '@/components/loaders/animation'
import { LoadingAnimation } from '@/components/loaders'
import shared from '@/shared/shared';
import ConfirmationModal from '@/components/common/confirmation-modal'
import assets from './assets';
import Axios from 'axios'
import { auth } from "@/service";
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'models',
  components: { Animation, LoadingAnimation, ConfirmationModal, assets, ModelListSelect },

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: null,
      loadingRows: false,
      templates: [],
      filter: null,
      editIndex: -1,
      currentTemplate: null,
      showFormModal: false,
      loadingCurrentTemplate: false,
      saving: false,
      imageDeleteIndex: -1,
      imageSelectIndex: -1,
      showImageSelectModal: false,
      selectedType: {},
      types: [
        { label: 'Todos', value: null },
        { label: 'Lote', value: 'itmlot' },
        { label: 'Endereço', value: 'locati' }
      ]
    }
  },

  methods: {
    findAll() {
      if (this.loadingRows) {
        return;
      }

      let conditions = [];

      if (this.filter && this.filter.length) {
        conditions.push({
          field: 'name',
          conditionalOperator: 'LIKE',
          value: this.filter
        });
      }

      if (this.selectedType && this.selectedType.value) {
        if (this.selectedType.value === 'itmlot') {
          conditions.push({
            logicalOperator: 'OR',
            conditions: [
              {
                field: 'type',
                value: this.selectedType.value
              },
              {
                field: 'type',
                conditionalOperator: 'IS_NULL'
              }
            ]
          });
        } else {
          conditions.push({
            field: 'type',
            value: this.selectedType.value
          });
        }
      }

      this.loadingRows = true;
      httpClient
      .post(`${process.env.VUE_APP_API_URL}templates/select-and-where?page=${this.currentPage - 1}&size=${this.perPage}`, {
        select: ['id', 'active', 'createdAt', 'updatedAt', 'name', 'type'],
        where: {
          conditions: conditions,
          orderBies: [
            {
              direction: 'DESC',
              condition: { field: 'active' }
            },
            {
              direction: 'DESC',
              condition: { field: 'createdAt' }
            }
          ]
        }
      }).then(({ data }) => {
        this.loadingRows = false;
        this.totalRows = data.data.totalElements;
        this.templates = data.data.content;

        this.templates.forEach(t => {
          t.formattedCreatedAt = shared.formatDate(t.createdAt);
          t.formattedUpdatedAt = shared.formatDate(t.updatedAt);
        })
      }).catch((error) => {
        this.loadingRows = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    pageChanged(page) {
      this.currentPage = page;
      this.findAll();
    },

    searchButtonPressed() {
      this.currentPage = 1;
      this.findAll();
    },

    openNewFormModal() {
      this.editIndex = -1;
      this.currentTemplate = { active: true };
      this.setFormImages();
      this.showFormModal = true;
    },

    save() {
      if (!this.currentTemplate.name || !this.currentTemplate.name.length) {
        this.$notify.textError('Nome não informado');
        return;
      }

      if (!this.currentTemplate.type || !this.currentTemplate.type.length) {
        this.$notify.textError('Tipo não informado');
        return;
      }

      if (!this.currentTemplate.content || !this.currentTemplate.content.length) {
        this.$notify.textError('Template não informado');
        return;
      }

      this.currentTemplate.name = this.currentTemplate.name.replace(/[^\w\s]/gi, '');

      try {
        this.prepareImages();
      } catch (e) {
        this.$notify.textError(e)
        return;
      }

      this.saving = true;
      let promise;

      if (this.currentTemplate.id) {
        promise = httpClient.put(`${process.env.VUE_APP_API_URL}templates`, this.currentTemplate);
      } else {
        promise = httpClient.post(`${process.env.VUE_APP_API_URL}templates`, this.currentTemplate);
      }

      promise.then(({ data }) => {
        this.saving = false;
        this.$notify.success('Modelo salvo com sucesso');
        data.data.formattedCreatedAt = shared.formatDate(data.data.createdAt);
        data.data.formattedUpdatedAt = shared.formatDate(data.data.updatedAt);

        if (this.editIndex > -1) {
          this.templates[this.editIndex] = data.data;
        } else {
          this.templates.unshift(data.data);
        }

        this.editIndex = -1;
        this.currentTemplate = {};
        this.showFormModal = false;
      })
      .catch((error) => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    uploadFile($event) {
      if (this.$refs.uploadFileInput
        && this.$refs.uploadFileInput.files
        && this.$refs.uploadFileInput.files.length) {
        let reader = new FileReader();
        let f = this.$refs.uploadFileInput.files[0];

        if (!f.name.endsWith('.jrxml')) {
          this.$notify.textError('Arquivo inválido');
          return;
        }

        this.saving = true;
        reader.onload = ((theFile) => {
          return (e) => {
            this.saving = false;

            try {
              this.currentTemplate.contentChanged = true;
              this.currentTemplate.content = e.target.result;
              this.$notify.success('Arquivo lido com sucesso');
            } catch (e) {
              console.error(e);
              this.$notify.textError('Houve um erro ao ler o arquivo');
            }
          }
        })(f);

        reader.onerror = (error) => {
          this.saving = false;
          console.error(error);
          this.$notify.textError('Houve um erro ao ler o arquivo');
        }

        reader.readAsText(f);
      }
    },

    editItem(index) {
      this.editIndex = index;
      this.showFormModal = true;
      this.loadingCurrentTemplate = true;
      httpClient.get(`${process.env.VUE_APP_API_URL}templates/${this.templates[index].id}`)
      .then(({ data }) => {
        this.loadingCurrentTemplate = false;
        this.currentTemplate = data.data;
        this.setFormImages();
      })
      .catch((error) => {
        this.loadingCurrentTemplate = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    downloadContent() {
      shared.downloadString(this.currentTemplate.content, 'application/xml', this.currentTemplate.name + '.jrxml');
    },

    formModalClosed() {
      this.editIndex = -1;
    },

    setFormImages() {
      if (!this.currentTemplate.templateAssets) {
        this.currentTemplate.templateAssets = [];
      }

      this.currentTemplate.images = this.currentTemplate.templateAssets.filter(ta => !ta.main).sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }

        return 0;
      });
    },

    openImageDeleteModal(index) {
      this.imageDeleteIndex = index;
      this.$bvModal.show('image-delete-confirmation-modal');
    },

    deleteImage() {
      let id = this.currentTemplate.images[this.imageDeleteIndex].id;
      this.currentTemplate.images.splice(this.imageDeleteIndex, 1);

      if (id) {
        let i = this.currentTemplate.templateAssets.findIndex(ta => ta.id === id);

        if (i > -1) {
          this.currentTemplate.templateAssets.splice(i, 1);
        }
      }

      this.reloadCurrentTemplate();
    },

    reloadCurrentTemplate() {
      let currentTemplate = this.currentTemplate;
      this.currentTemplate = {};
      this.currentTemplate = currentTemplate;
    },

    openImageSelectModal(index) {
      this.imageSelectIndex = index;
      this.showImageSelectModal = true;

      this.$nextTick(() => {
        this.$refs.assetList.findAll();
      });
    },

    imageSelected(asset) {
      if (!asset || !asset.name || asset.type !== 'I') {
        this.$notify.textError('Arquivo inválido');
        return;
      }

      this.showImageSelectModal = false;

      if (this.imageSelectIndex > -1) {
        this.currentTemplate.images[this.imageSelectIndex].asset = asset;
      } else {
        let templateAsset = {
          name: asset.name,
          asset: asset
        };

        if (templateAsset.name && templateAsset.name.includes('.')) {
          templateAsset.name = templateAsset.name.substring(0, templateAsset.name.indexOf('.'));
        }

        templateAsset.name = templateAsset.name.replace(/[^\w\s]/gi, '');

        this.currentTemplate.images.push(templateAsset);
      }

      this.reloadCurrentTemplate();
    },

    prepareImages() {
      if (!this.currentTemplate.images) {
        this.currentTemplate.images = [];
      }

      if (!this.currentTemplate.templateAssets) {
        this.currentTemplate.templateAssets = [];
      }

      this.currentTemplate.images.forEach(image => {
        let isValid = image.name && image.name.length && image.name === image.name.replace(/[^\w\s]/gi, '');

        if (!isValid) {
          throw 'Nome inválido: ' + image.name + '. Não utilize acentos nem caracteres especiais';
        }

        if (image.id) {
          let index = this.currentTemplate.templateAssets.findIndex(ta => ta.id === image.id);
          this.currentTemplate.templateAssets[index] = image;
        } else {
          this.currentTemplate.templateAssets.push(image);
        }
      });
    },

    downloadExample(type) {
      this.saving = true;
      Axios({
        url: process.env.VUE_APP_API_URL + `templates/examples/etiqueta/` + type,
        method: 'GET',
        responseType: 'blob',
        headers: auth.getAxiosHeaders()
      }).then((response) => {
        this.saving = false;
        if (response && response.data && response.data.size) {
          shared.downloadString(response.data, 'application/xml', response.headers.filename + '.jrxml');
        } else {
          this.$notify.textError('Houve um erro ao baixar o exemplo');
        }
      }).catch((error) => {
        this.saving = false;
        console.error(error);
        this.$notify.textError('Houve um erro ao baixar o exemplo');
      });
    },

    copyImageCode(index) {
      let code = '$P{IMAGENS}.get("' + this.currentTemplate.images[index].name + '")';
      console.log(code);
      shared.copyText(code).then(() => {
        this.$notify.success('Código copiado');
      }).catch(error => {
        console.error(error);
        this.$notify.success('Houve um erro ao copiar');
      });
    },
  },
}
</script>
